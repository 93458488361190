import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime,  pumpLink, dexscreenerLink, get_user_flow, BASE_API, TokenType} from '../components/Helper';


interface TokenComplete {
  timestamp: number;
  delay: number;
  mint : string;
  bondingCurve: string;
  user: string;
  isNew : boolean,
}

const PumpComplete : React.FC = () => {

  const refs = {
      complete: useRef(true),
      walletToken: useRef(true),
      balance: useRef(true),
      dexpaid : useRef(true),
  };

   const pairURL = "wss://ws.neutron.ag/pump/complete"; //8002

   const [dataList, setDataList] = useState<TokenComplete[]>([]);
   const [countList, setCountList] = useState<CountNumber[]>([]);

   useEffect(() => {
     fetch(BASE_API + "/pump/day/complete")
       .then(response => {
         return response.json(); 
       })
       .then(data => {
         setCountList(data);
       })
       .catch(error => {
           console.log('Fetch Error:', error);
       });
 
   }, []);

   //#region : LOAD DEXPAID 
   const [dexpaidList, setDexpaidList] = useState<DexpaidOrder[]>([]);
   useEffect(() => {
      if(refs.dexpaid.current === true){
        fetch('https://api.ledinhhuy.com/dexpaid/chain/solana?limit=50')
          .then(response => {
            return response.json(); 
          })
          .then(data => {
              setDexpaidList(data);
              refs.dexpaid.current = false; // Reset cờ sau khi xử lý xong
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
      }
   },[dataList]);
   //#endregion


   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: TokenComplete[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    delay: item.delay,
                    mint : item.mint,
                    bondingCurve: item.bondingCurve,
                    user: item.user,
                  
                    isNew : false,
                }));


                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                if(message.data.length > 0) {
                 
                  refs.dexpaid.current = true;
                }
                
                const formattedPair: TokenComplete[] = message.data.map((item: any) => ({
                  timestamp: item.timestamp,
                  delay: item.delay,
                  mint : item.mint,
                  bondingCurve: item.bondingCurve,
                  user: item.user,

                  isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);



    return (
      <><Header/>
        <div className="mx-auto p-2">
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr>
                        <th className="border border-gray-600 px-2 py-1">N</th>
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Delay</th>
                        <th className="border border-gray-600 px-2 py-1">PAID</th>
                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Curve</th>
                        <th className="border border-gray-600 px-2 py-1">Owner</th>
                     
                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {

                      const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);

                      return (

                        <tbody>
                          <tr key={item.mint} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >

                            <td className='px-2 py-1 text-gray-800'>{index+1}</td>
                            <td className='px-2 py-1'>{varTime(item.timestamp /1000)}</td>
                            <td className='px-2 py-1'>{(item.delay/1000).toFixed(1)} s</td>
                            <td className='px-2 py-1'>{check_dexpaid?(<div className='flex'><img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"} </td>

                            <td className='px-2 py-1'>{shortTokenLink(item.mint, TokenType.TOKEN)}</td>
                            <td className='px-2 py-1'>{shortTokenLink(item.bondingCurve, TokenType.ACCOUNT)}</td>
                            <td className='px-2 py-1 space-x-2'>{shortTokenLink(item.user, TokenType.ACCOUNT)}
                                  {get_user_flow(item.mint, item.user)}

                            </td>
                           
                            <td className='px-2 py-1 underline space-x-2'>
                                <a target="_blank" rel="noreferrer" href={dexscreenerLink(item.mint)}>Dex</a>
                                <a target="_blank" rel="noreferrer" href={pumpLink(item.mint)}>Pump</a>
                                
                            </td>
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>
           
            <div className="w-full flex flex-wrap  overflow-x-auto gap-4 mt-4">
                  {
                    countList?.map(item => (<div className="border border-gray-600 p-1 text-sm"><div className='text-gray-500'>{item._id}</div> <div>{item.count} </div></div> ))
                  }
            </div>
        </div>
      </>
    )
  
}
  
export default PumpComplete;