import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import { shortTokenLink, varTime, dexscreenerLink, pumpLink, BASE_API, solNumber, get_user_flow, TokenType, formatTimestamp, solCap} from '../components/Helper';
import { useLocation } from 'react-router-dom';
import { useBalanceList, useCompleteList, useCountList, useDexpaidList, useWalletTokenList } from 'src/components/Utils';

const PairD : React.FC = () => {
  const refs = {
      complete: useRef(true),
      walletToken: useRef(true),
      balance: useRef(true),
      dexpaid : useRef(true),
  };

  const [dataList, setDataList] = useState<Pair[]>([]);

  const pairURL = "wss://ws.dexbull.com/pools"; // const pairURL = "ws://localhost:9443/pairs";

  const countList = useCountList("https://api.dexbull.com/pair-day/solana");
  const completeList = useCompleteList(dataList, refs);
  const walletTokenList = useWalletTokenList(dataList, refs);
  const balanceList = useBalanceList(dataList, refs, "baseVault");
  const dexpaidList = useDexpaidList(dataList, refs);
 

   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);
      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;
            

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Pair[] = message.data.map((item: any) => ({
                    
                    mint : item.mint,
                    poolId: item.poolId,
                    creation_ts: item.creation_ts,
                    createdAt : item.createdAt,
                    txId : item.txId,
                    baseVault : item.baseVault,
                    init_coin_amount : item.order?item?.initLiquidity.init_coin_amount : item?.initLiquidity.init_pc_amount,
                    init_pc_amount : item.order?item?.initLiquidity.init_pc_amount : item?.initLiquidity.init_coin_amount,

                    isNew : false,
                }));

                console.log('initial', formattedPair.length);
                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                if(message.data.length > 0) {
              
                  refs.complete.current = true;
                  refs.walletToken.current = true;
                  refs.balance.current = true;
                  refs.dexpaid.current = true;
                }


                const formattedPair: Pair[] = message.data.map((item: any) => ({
                    
                    mint : item.mint,
                    poolId: item.poolId,
                    creation_ts: item.creation_ts,
                    createdAt : item.createdAt,
                    txId : item.txId,
                    baseVault : item.baseVault,
                    init_coin_amount : item.order?item?.initLiquidity.init_coin_amount : item?.initLiquidity.init_pc_amount,
                    init_pc_amount : item.order?item?.initLiquidity.init_pc_amount : item?.initLiquidity.init_coin_amount,

                    isNew : true,
                }));

                console.log('new', formattedPair.length);
                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                    

                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);



   /*-------------- BUY POOL --------------*/

   const [clickedButtons, setClickedButtons] = useState({});
   const [clickedButtons_sell, setClickedButtons_sell] = useState({});

   const [tooltipVisible, setTooltipVisible] = useState(false);

   const [result, setResult] = useState<SwapResult | null>();

    const handleBuyToken = async(poolId : string) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(BASE_API + '/buy/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }

    const handleSellToken = async(poolId : string) => {
        setClickedButtons_sell((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        setResult(null);

        fetch(BASE_API + '/sell/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
              setTooltipVisible(true);
              setResult(data);

              setClickedButtons_sell({poolId : false})

              setTimeout(() => {
                setTooltipVisible(false);
              }, 60000);

          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }
    

    return (
      <><Header/>
        <div className="mx-auto p-2">
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr className="uppercase">
                        <th className="border border-gray-600 px-2 py-1">N</th>
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Delay</th>
                        <th className="border border-gray-600 px-2 py-1">Init</th>
                        <th className="border border-gray-600 px-2 py-1">PAID</th>

                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Pool</th>
                        <th className="border border-gray-600 px-2 py-1">Base</th>
                        <th className="border border-gray-600 px-2 py-1">Liquid</th>
                      
                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                        <th className="border border-gray-600 px-2 py-1">Action</th>

                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      const check_walletToken = walletTokenList.find(p => p.mint === item.mint );
                      const check_balance = balanceList?.find(p => p.address === item.baseVault);
                      
                      const check_complete = completeList.find(p => p.mint === item.mint );
                      const user_address = check_complete?check_complete.user: null;

                      const check_dexpaid = dexpaidList?.find(p => p.tokenAddress === item.mint);

                      //LINKS
                      const link_poolTx = 'https://solscan.io/tx/' + item.txId;
                      const link_pool_by_pair = 'https://api.dexbull.com/pool/' + item.poolId;
                      const link_token_by_mint = 'https://api.dexbull.com/token/' + item.mint;
                      const link_cap = 'https://api-sol.dexbull.com/mint/cap/' + item.mint;
                      const link_balance = 'https://api.neutron.ag/balance/sol/' + item.baseVault;

                      return (

                        <tbody>
                          <tr key={item.mint} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <td className='px-2 py-1 text-gray-800'>{index+1}</td>
                            <td className='px-2 py-1'>{varTime(Date.parse(item.createdAt) / 1000)}</td> 
                            <td className='px-2 py-1'>{formatTimestamp((Date.parse(item.createdAt) - item.creation_ts)/1000)}
                                <a target="_blank" href={link_poolTx}><span className='float-right p-2'>→</span></a>
                            </td>
                            <td className='px-2 py-1'>{solNumber(item.init_coin_amount, true)}</td>
                            <td className='px-2 py-1'>{check_dexpaid?(<div className='flex'>
                                  <img width="24" height="24" src={check_dexpaid.icon}/> <span className='ml-2 text-sm'>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"} </td>

                            <td className='px-2 py-1'>{shortTokenLink(item.mint, TokenType.TOKEN)}
                                <a target="_blank" href={link_token_by_mint}><span className='float-right p-2'>→</span></a>
                            </td>
                            <td className='px-2 py-1'>{shortTokenLink(item.poolId, TokenType.ACCOUNT)}
                                <a target="_blank" href={link_pool_by_pair}><span className='float-right p-2'>→</span></a>
                            </td>
                            <td className='px-2 py-1'>{shortTokenLink(item.baseVault, TokenType.ACCOUNT)}
                              <a target="_blank" href={link_balance}><span className='float-right p-2'>→</span></a>
                            </td>
                            <td className='px-2 py-1'>
                              {check_balance?solCap(check_balance.balance):'-'}
                            </td>

                            <td className='px-2 py-1 underline space-x-2'>
                                <a target="_blank" href={dexscreenerLink(item.mint)}>Dex</a>
                                <a target="_blank" className={ check_complete?'' : 'text-gray-800'} href={pumpLink(item.mint)}>Pump</a>
                                {user_address?(shortTokenLink(user_address, TokenType.ACCOUNT)):''}
                                {get_user_flow(item.mint, user_address)}

                            </td>
                            <td className='px-2 py-1 underline space-x-2'>
                                <button onClick={() => handleBuyToken(item.poolId)} className='bg-gray-500 px-2'>
                                  {clickedButtons[item.poolId] ? 'BUY...' : 'BUY'}
                                </button>

                                {
                                  check_walletToken?(<button onClick={() => handleSellToken(item.poolId)} className='bg-gray-500 px-2'>
                                    {clickedButtons_sell[item.poolId] ? 'SELL...' : 'SELL'}
                                  </button>):''
                                }

                                <a target="_blank" href={link_cap}><span className='float-right p-2'>→</span></a>
                            </td>
                           
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>

            <div className="w-full flex flex-wrap  overflow-x-auto gap-4 mt-4">
                  {
                    countList?.map(item => (<div className="border border-gray-600 p-1 text-sm"><div className='text-gray-500'>{item._id}</div> <div>{item.count} </div></div> ))
                  }
            </div>
           
        </div>

        {/* Tooltip cố định ở bottom */}
        {tooltipVisible && (
          <div
            data-tooltip-id="bottom-tooltip"
            className="fixed bottom-4 left-0 w-full flex justify-center"
          >
            <div className="bg-gray-800 text-white px-4 py-2 rounded shadow-lg">
              <p>
              <strong>Status:</strong>{" "}
              <span className="text-green-400">{result?.status}</span>
              </p>

              <p>
                <strong>Mint:</strong>
                <br />
                <code>{result?.mint}</code>
              </p>

              <p>
                <strong>Signature:</strong>
                <br />
                <code>{result?.signature}</code>
              </p>
              
              <a
                href={result?.url!}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline"
              >
                View on Solscan
              </a>
            </div>
          </div>
        )}

      </>
    )
  
}
  
export default PairD;
