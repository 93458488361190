import Header from '../components/header/Header';
import React, { useState, useEffect, useRef} from 'react';
import {shortTokenLink, varTime, dexscreenerLink, formatTimestamp, pumpLink, BASE_API, TokenType, get_user_flow} from '../components/Helper';
import { useCompleteList } from 'src/components/Utils';


const DexPaid : React.FC = () => {
  const refs = {
      complete: useRef(true),
  };
   const wsURL = "wss://ws.ledinhhuy.com/dexpaid";
    // const wsURL = "ws://localhost:9443/pairs";

   const [dataList, setDataList] = useState<DexpaidOrder[]>([]);
   const completeList = useCompleteList(dataList, refs);

   useEffect(() => {
      fetch("https://api.ledinhhuy.com/dexpaid/chain/solana?limit=20")
          .then(response => {
            return response.json(); 
          })
          .then(data => {
              setDataList(data);
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
  },[dataList]);

    /*
   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(wsURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Order[] = message.data.map((item: any) => ({
                    icon: item.icon,
                    header: item.header,
                    paymentTimestamp: item.paymentTimestamp,
                    tokenAddress : item.tokenAddress,
                    createdAt: item.createdAt,
                   
                    isNew : false,
                }));

                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                const formattedPair: Order[] = message.data.map((item: any) => ({
                    icon: item.icon,
                    header: item.header,
                    paymentTimestamp: item.paymentTimestamp,
                    tokenAddress : item.tokenAddress,
                    createdAt: item.createdAt,

                    isNew : true,
                }));

                console.log('formattedPair', formattedPair);

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //10s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);
    */


   /*-------------- BUY  MINT --------------*/

   const [clickedButtons, setClickedButtons] = useState({});

    const handleBuyToken = async(mint) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [mint]: !prevState[mint], // Đảo trạng thái khi nút được bấm
        }));

        //alert(`Button clicked for item with id: ${poolId}`);
        fetch(BASE_API + '/buy/mint/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: mint}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }

   return (
      <><Header/>
        <div className="mx-auto p-2">
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr className="uppercase">
                        <th className="border border-gray-600 px-2 py-1">N</th>
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Delay</th>

                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Icon</th>
                        <th className="border border-gray-600 px-2 py-1">Header</th>

                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                        <th className="border border-gray-600 px-2 py-1">Action</th>

                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      const check_complete = completeList.find(p => p.mint === item.tokenAddress );
                      const user_address = check_complete?check_complete.user: null;

                      return (

                        <tbody>
                          <tr key={item.tokenAddress} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <td className='px-2 py-1 text-gray-800'>{index+1}</td>
                            <td className='px-2 py-1'>{varTime(item.paymentTimestamp / 1000)}</td>
                            <td className='px-2 py-1'>{ formatTimestamp((Date.parse(item.createdAt) - item.paymentTimestamp)/1000)}</td>

                            <td className='px-2 py-1'>{shortTokenLink(item.tokenAddress, TokenType.TOKEN)}</td>
                            <td className='px-2 py-1'><img src={item.icon} className="h-8 w-8"/></td>
                            <td className='px-2 py-1'><img src={item.header} className="h-8"/></td>
                         
                            <td className='px-2 py-1 underline space-x-2'>

                                <a target="_blank" href={dexscreenerLink(item.tokenAddress)}>Dex</a>
                                <a target="_blank" className={ check_complete?'' : 'text-gray-800'} href={pumpLink(item.tokenAddress)}>Pump</a>
                                {user_address?(shortTokenLink(user_address, TokenType.ACCOUNT)):''}
                                {get_user_flow(item.tokenAddress, user_address)}
                                
                            </td>

                            <td className='px-2 py-1 underline space-x-2'>
                                <button onClick={() => handleBuyToken(item.tokenAddress)} className='bg-gray-500 px-2'>
                                  {clickedButtons[item.tokenAddress] ? 'BUY...' : 'BUY'}
                                </button>
                            </td>

                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>
           
        </div>
      </>
    )
  
}
  
export default DexPaid;