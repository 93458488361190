import Header from '../components/header/Header';
import React, { useState, useEffect,} from 'react';
import {shortTokenLink, varTime, dexscreenerLink, pumpLink, BASE_API, formatNumber, formatToken, TokenType} from '../components/Helper';

const SwapLog : React.FC = () => {

   const pairURL = "wss://ws.neutron.ag/swaps"; //8002

   const [dataList, setDataList] = useState<Swap[]>([]);

   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: Swap[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    duration : item.duration,
                    direction: item.direction,
                    mint : item.mint,
                    poolId : item.poolId,
                    wallet: item.wallet,
                    tx: item.tx,
                    solAmount : item.solAmount,
                    splAmount : item.splAmount,
                    PnL : item.PnL,
                    mode : item.mode,

                    isNew : false,
                }));

              
                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                const formattedPair: Swap[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    duration : item.duration,
                    direction: item.direction,
                    mint : item.mint,
                    poolId : item.poolId,
                    wallet: item.wallet,
                    tx: item.tx,
                    solAmount : item.solAmount,
                    splAmount : item.splAmount,
                    PnL : item.PnL,
                    mode : item.mode,

                    isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);


   /*-------------- SELL POOL --------------*/

    const [clickedButtons, setClickedButtons] = useState({});


    const handleSellToken = async(poolId) => {
        setClickedButtons((prevState) => ({
          ...prevState,
          [poolId]: !prevState[poolId], // Đảo trạng thái khi nút được bấm
        }));

        fetch(BASE_API + '/sell/pool/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify({id: poolId}), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
              return response.json(); // hoặc response.text(), response.blob() tùy vào kiểu dữ liệu cần lấy
          })
          .then(data => {
              //cập nhật trạng thái
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
    }


    return (
      <><Header/>
        <div className="mx-auto p-2">
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr className='uppercase'>
                         <th className="border border-gray-600 px-2 py-1">N</th>
                        <th className="border border-gray-600 px-2 py-1">Time</th>
                        <th className="border border-gray-600 px-2 py-1">Exe</th>
                        <th className="border border-gray-600 px-2 py-1">Type</th>                        <th className="border border-gray-600 px-2 py-1">Type</th>
                        <th className="border border-gray-600 px-2 py-1">Mode</th>
                        <th className="border border-gray-600 px-2 py-1">Mint</th>
                        <th className="border border-gray-600 px-2 py-1">Pair</th>
                        <th className="border border-gray-600 px-2 py-1">Wallet</th>
                        <th className="border border-gray-600 px-2 py-1">Tx</th>
                        <th className="border border-gray-600 px-2 py-1">SOL</th>
                        <th className="border border-gray-600 px-2 py-1">SPL</th>
                        <th className="border border-gray-600 px-2 py-1">PnL</th>

                        <th className="border border-gray-600 px-2 py-1">Dex</th>
                        <th className="border border-gray-600 px-2 py-1">Action</th>
                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      return (

                        <tbody>
                          <tr key={item.mint} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <td className='px-2 py-1 text-gray-800'>{index+1}</td>
                            <td className='px-2 py-1'>{varTime(item.timestamp /1000)}</td>
                            <td className='px-2 py-1'>{item.duration / 1000} s</td>
                            <td className='px-2 py-1'>{item.direction}</td>
                            <td className='px-2 py-1'>{item.mode}</td>
                            <td className='px-2 py-1'>{shortTokenLink(item.mint, TokenType.TOKEN)}</td>
                            <td className='px-2 py-1'>{shortTokenLink(item.poolId, TokenType.ACCOUNT)}</td>
                            <td className='px-2 py-1'>{shortTokenLink(item.wallet, TokenType.ACCOUNT)}</td>
                            <td className='px-2 py-1'>{shortTokenLink(item.tx, TokenType.TX)}</td>
                            <td className='px-2 py-1'>{Number(item.solAmount) /10 ** 9}</td>
                            <td className='px-2 py-1'>{formatToken((Number(item.splAmount) /10 ** 6).toFixed(0))}</td>

                            <td className='px-2 py-1'>{item.PnL} %</td>

                            <td className='px-2 py-1 underline space-x-2'>
                                <a target="_blank" href={dexscreenerLink(item.mint)}>Dex</a>
                                <a target="_blank" href={pumpLink(item.mint)}>Pump</a>
                            </td>     

                            <td className='px-2 py-1 underline space-x-2'>
                                <button onClick={() => handleSellToken(item.poolId)} className='bg-gray-500 px-2'>
                                  {clickedButtons[item.poolId] ? 'SELL...' : 'SELL'}
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>
           
        </div>
      </>
    )
  
}
  
export default SwapLog;