import Header from '../components/header/Header';
import React, { useState, useEffect } from 'react';
import {shortTokenLink, varTime, pumpLink, dexscreenerLink, BASE_API, TokenType} from '../components/Helper';


interface TokenNew {
  timestamp: number;
  mint : string;
  bondingCurve: string;
  user: string;

  isNew : boolean,
}

const PumpNew : React.FC = () => {

    const pairURL = "wss://ws.neutron.ag/pump/new"; //8002

   const [dataList, setDataList] = useState<TokenNew[]>([]);

   const [dateList, setDateList] = useState<CountNumber[]>([]);

    useEffect(() => {
      fetch( BASE_API + "/pump/day/new")
        .then(response => {
          return response.json(); 
        })
        .then(data => {
          setDateList(data);
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });

    }, [dataList]);


   useEffect(() => {
      // Kết nối WebSocket
      const ws = new WebSocket(pairURL);

      // Xử lý tin nhắn từ server
      ws.onmessage = (event) => {
        try{
            if(!event.data) return;
            const message = JSON.parse(event.data);
            if(!message) return;

            if (message.action === "initial") {
              // Nhận danh sách sản phẩm ban đầu
              if (Array.isArray(message.data)) {
                const formattedPair: TokenNew[] = message.data.map((item: any) => ({
                    timestamp: item.timestamp,
                    // delay: item.delay,
                    mint : item.mint,
                    bondingCurve: item.bondingCurve,
                    user: item.user,
                  
                    isNew : false,
                }));


                setDataList(formattedPair);
              } else {
                console.error("Expected an array but got:", message.data);
              }

              
            } else if (message.action === "new") {
              
              if (Array.isArray(message.data)) {
                const formattedPair: TokenNew[] = message.data.map((item: any) => ({
                  timestamp: item.timestamp,
                  // delay: item.delay,
                  mint : item.mint,
                  bondingCurve: item.bondingCurve,
                  user: item.user,

                  isNew : true,
                }));

                setDataList((prevPair) => {
                  const allPair = [...formattedPair, ...prevPair];

                  setTimeout(() => {
                    setDataList((prevState) =>
                      prevState.map((product) => ({ ...product, isNew: false }))
                    );
                  }, 60000); //60s

                  return allPair;
              } );


              } else {
                console.error("Expected an array but got:", message.data);
              }
            }
          }
          catch(error : any){
            
          }
      };

      ws.onopen = () => {
        console.log("WebSocket connected");
      };
  
      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => ws.close(); // Cleanup WebSocket connection
   },[]);



    return (
      <><Header/>
        <div className="mx-auto p-4 mt-4">
            
            <div className="w-full md:flex  overflow-x-auto">
              <table className="table-auto w-full  mt-4 table-tokens" key="list">
                  <thead> 
                      <tr>
                        <th className="border border-gray-600 px-2 py-2">N</th>
                        <th className="border border-gray-600 px-2 py-2">Time</th>
                        {/* <th className="border border-gray-600 px-2 py-2">Delay</th> */}
                        
                        <th className="border border-gray-600 px-2 py-2">Mint</th>
                        <th className="border border-gray-600 px-2 py-2">Curve</th>
                        <th className="border border-gray-600 px-2 py-2">Owner</th>
                     
                        <th className="border border-gray-600 px-2 py-2">Dex</th>
                      </tr>
                  </thead>
                  {dataList?.slice(0, 20).map((item, index) => {
                      return (

                        <tbody>
                          <tr key={item.mint} className="hover:bg-slate-900"
                            style={{
                              backgroundColor: item.isNew ? "grey" : "transparent", // Highlight sản phẩm mới
                              transition: "background-color 0.3s ease",
                            }}
                          >

                            <td className='px-2 py-2'>{index+1}</td>
                            <td className='px-2 py-2'>{varTime(item.timestamp /1000)}</td>
                            {/* <td className='px-2 py-2'>{(item.delay/1000).toFixed(1)} s</td> */}
                            <td className='px-2 py-2'>{shortTokenLink(item.mint, TokenType.TOKEN)}</td>
                            <td className='px-2 py-2'>{shortTokenLink(item.bondingCurve, TokenType.ACCOUNT)}</td>
                            <td className='px-2 py-2'>{shortTokenLink(item.user, TokenType.ACCOUNT)}</td>
                           
                            <td className='px-2 py-2 underline'><a target="_blank" rel="noreferrer" href={pumpLink(item.mint)}>Pump</a></td>
                            <td className='px-2 py-2 underline space-x-2'>
                                <a target="_blank" rel="noreferrer" href={dexscreenerLink(item.mint)}>Dex</a>
                                <a target="_blank" rel="noreferrer" href={pumpLink(item.mint)}>Pump</a>
                            </td>                             
                          </tr>
                        </tbody>
                      )
                  })}
                </table>

            </div>
           
            <div className="w-full md:flex  overflow-x-auto space-x-2 mt-4">
                  {
                    dateList?.map(item => (<div className="border border-gray-600 p-1 text-sm"><span className='text-gray-500'>{item._id}</span> : {item.count} </div> ))
                  }
            </div>
            
        </div>
      </>
    )
  
}
  
export default PumpNew;