import Header from '../components/header/Header';
import React, { useState, useEffect} from 'react';
import {shortTokenLink, varTime, dexscreenerLink, formatTimestamp, pumpLink, BASE_API, TokenType} from '../components/Helper';

interface Owner {
  address: string;
  count : string;
  isNew : boolean,
}


const PumpOwner : React.FC = () => {

   const [list, setList] = useState<Owner[]>([]);

   useEffect(() => {
      fetch(BASE_API + "/log/owner")
          .then(response => {
            return response.json(); 
          })
          .then(data => {
            setList(data);
          })
          .catch(error => {
              console.log('Fetch Error:', error);
          });
   },[list]);

  
   return (
      <><Header/>
        <div className="mx-auto p-2">
            <div className="w-full flex flex-wrap overflow-x-auto gap-4 mt-4">
                  {
                   list?.slice(0, 40).map((item) => (<div className="border border-gray-600 p-1 text-sm"><div className='text-gray-500'>{shortTokenLink(item.address, TokenType.TOKEN)}</div><div>{item.count} </div></div> ))
                  }
            </div>
        </div>
      </>
    )
  
}
  
export default PumpOwner;