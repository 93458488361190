import React, { useState , useEffect} from 'react';
import Navigation from './Navigation';
import { shortTokenLink , BASE_API, TokenType} from '../Helper';

interface Wallet {
    pubKey : string;
    primary : number;
}

interface Balance {
    sol : number;
    wsol : number;
}

interface Setting {
    simulation? : number;
    amount? : number;
    jito_fee? : number;
    buy_slippage? : number;
    sell_slippage? : number;
    fee_wallet? : string;
}

const Header = () => {

    const [walletList, setWalletList] = useState<Wallet[]>([]);
    const [primaryWallet, setPrimaryWallet] = useState<string>();

    const [balance, setBalance] = useState<Balance>();

    const [setting, setSetting] = useState<Setting>();

    /*---------- BALANCE ------------- */
    useEffect(() => {
        if(primaryWallet){
            fetch(BASE_API + '/balance/sol-wsol/' + primaryWallet)
                .then(response => {
                return response.json();
                })
                .then(data => {
                    setBalance(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    },[primaryWallet]);

    /*---------- WALLET ------------- */

    useEffect(() => {
        fetch(BASE_API + '/wallet/all')
            .then(response => {
              return response.json(); 
            })
            .then(data => {
              
                setWalletList(data);
                setPrimaryWallet(data.find(item => Number(item.primary) === 1)?.pubKey);

            })
            .catch(error => {
              console.log('Fetch Error:', error);
            });
    },[]);
    /*---------- SETTING ------------- */

    useEffect(() => {
        fetch(BASE_API + '/setting/main')
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setSetting(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        
    },[]);


    /*---------- POUPUP WALLET------------- */
    const [isOpenWallet, setIsOpenWallet] = useState(false);
    const [selectedWalletOption, setSelectedWalletOption] = useState(null);

    const togglePopupWallet = () => setIsOpenWallet(!isOpenWallet);

    const handleOptionSelectWallet = async(address) => {
        setIsOpenWallet(false); // Đóng popup sau khi chọn

        //setSelectedWalletOption(address);

        fetch(BASE_API + '/wallet/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({primary : address}),
        }).then(response => {
            if(response.ok){
                setPrimaryWallet(address);
            }
        });

    };

    /*---------- POUPUP SETTING ------------- */

    const [isOpenSetting, setIsOpenWalletSetting] = useState(false);

    const togglePopupSetting = () => setIsOpenWalletSetting(!isOpenSetting);

    const handleUpdateSetting = async() => {
        setIsOpenWalletSetting(false); // Đóng popup sau khi chọn

        fetch(BASE_API + '/setting/update/', {
            method: 'POST', // Phương thức POST
            headers: {
              'Content-Type': 'application/json', // Đảm bảo rằng server nhận dữ liệu dưới dạng JSON
            },
            body: JSON.stringify(setting), // Chuyển đối tượng JavaScript thành chuỗi JSON
        }).then(response => {
            if(response.ok){
                
            }
        });
    };

    const handleSettingChange = (event : any) => {
        const { name, value } = event.target;
        setSetting({
        ...setting,
        [name]: value
        });
    }


    return (
    <>
    {/* justify-center */}
        <div className="mx-auto bg-primary border-head-bottom  md:flex p-2 pl-4 pr-4 text-sm">
                <div className="md:w-1/2 max-sm:w-full max-sm:overflow-x-auto flex pt-1 pb-1  gap-4  items-center">
                    <Navigation/>
                </div>
                <div className="md:w-1/2 max-sm:w-full md:flex pt-1 pb-1  gap-4 ">
                    <div className='w-full flex space-x-2 pt-1'>
                        <div>MODE: {setting?.simulation == 0?'🟢':'🟡'}</div>
                        <div>S:BUY: <span className='text-highlight'>{setting?.buy_slippage}</span></div>
                        <div>S:SELL: <span className='text-highlight'>{setting?.sell_slippage}</span></div>
                        <div>JITO: <span className='text-highlight'>{setting?.jito_fee}</span></div>
                        <div>W:FEE: <span className='text-highlight'>{shortTokenLink(setting?.fee_wallet, TokenType.ACCOUNT)}</span></div>
                        <div><button onClick={() => togglePopupSetting()}>✏️</button></div>
                    </div>

                    <div className='w-full pt-1'>
                        
                        <div className='flex space-x-2 md:float-right'>
                            
                            <div onClick={togglePopupWallet}>[{ walletList.length}]</div>
                            <div>{shortTokenLink(primaryWallet, TokenType.ACCOUNT)}</div>
                            <div className='text-sm'>SOL : {Number(balance?.sol).toFixed(3)} | WSOL : {Number(balance?.wsol).toFixed(3)}</div>
                        </div>
                    </div>
                                       
                </div>    
                
        </div>
        <div className='w-full mx-auto justify-center items-center'>
                <div className="flex space-x-2 justify-center mt-2">
                    <div className="clock w-30 text-highlight" id="utc-clock">00:00:00 UTC</div>

                    <div className="flex items-center">
                        <span className="ml-3 mr-2">⚡ BUY</span>
                        <label className="relative inline-block w-10 h-6">
                            <input type="checkbox" className="peer hidden" />
                            <span className="absolute top-0 left-0 w-full h-full bg-gray-300 rounded-full transition peer-checked:bg-blue-500"></span>
                            <span
                            className="absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition peer-checked:translate-x-4"
                            ></span>
                        </label>
                        
                    </div>


                    <input
                        type="text"
                        value={setting?.amount}
                        className="border border-gray-300 ocus:outline-none text-black w-16"
                    />  
                </div>
        </div>

        <div>
            {isOpenWallet && (
                    <div className="popup">
                        <table>
                            <tbody>
                            {
                                walletList.map(item => 
                                    (<tr><td>{item.pubKey}{item.primary == 1?' - Primary':''}</td><td><button className='border px-1 my-1' onClick={() => handleOptionSelectWallet(item.pubKey)}>Choose</button></td></tr>)
                                )
                            }
                            </tbody>
                           
                        </table>
                    </div>
                )}

                <style>
                        {`
                        .popup {
                            position: absolute;
                            top: 50px;
                            right: 0px;
                            transform: translateX(-50%);
                            background-color: black;
                            border: 1px solid #ccc;
                            padding: 10px;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            z-index: 1000;
                        }
                        
                        input {
                            color : black;
                        }

                        .btn {
                            padding: 10px 20px;
                            background-color: #4CAF50;
                            color: white;
                            border: none;
                            cursor: pointer;
                        }
                        .btn:hover {
                            background-color: #45a049;
                        }
                        ul {
                            list-style-type: none;
                            padding: 0;
                        }
                        li {
                            padding: 8px;
                            cursor: pointer;
                        }
                        button:hover {
                            background-color: #24283e;
                        }
                        `}
                </style>
        </div>
        <div>
            {isOpenSetting && (
                <div className="popup">
                    <table>
                        <tbody>
                            <tr><td>Slippage Buy</td><td><input name="buy_slippage" type='text' defaultValue={setting?.buy_slippage} onChange={handleSettingChange}/></td></tr>
                            <tr><td>Slippage Sell</td><td><input name="sell_slippage" type='text' defaultValue={setting?.sell_slippage} onChange={handleSettingChange}/></td></tr>
                            <tr><td>JITO Fee (SOL)</td><td><input name="jito_fee" type='text' defaultValue={setting?.jito_fee} onChange={handleSettingChange}/></td></tr>
                            <tr><td>Amount</td><td><input name="amount" type='text' defaultValue={setting?.amount} onChange={handleSettingChange}/></td></tr>
                            <tr><td>MODE</td><td><input name="simulation" type='number' defaultValue={setting?.simulation} onChange={handleSettingChange}/></td></tr>
                            <tr><td></td><td><button className="border px-1 mt-1" onClick={() => handleUpdateSetting()}>UPDATE</button></td></tr>
                        </tbody>
                    </table>
                    
                </div>
            )}
        </div>

        </>
      

    );
}

export default Header