import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../neutron-logo-core.png';

export default function MenuNavigation() {
    const [activeItem, setActiveItem] = useState('home'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/') {
          setActiveItem('home');
        } 
        else if (location.pathname.includes('/tool/pair_d')) { // from listen log - RAYDIUM_LIQUIDITY_PROGRAM_ID_V4 (DexBull)
          setActiveItem('tool-pair-d');
        } 
        else if (location.pathname.includes('/tool/pair_n')) { // from listen program - LIQUIDITY_STATE_LAYOUT_V4 (Neutron)
            setActiveItem('tool-pair-n');
        } 
        else if (location.pathname.includes('/tool/pair_c')) { // from listen transaction - Raydium Program account (TokenCheck)
            setActiveItem('tool-pair-c');
        } 
        else if (location.pathname.includes('/tool/pump-king')) {
            setActiveItem('tool-pump-king');
        } 
        else if (location.pathname.includes('/tool/pump-complete')) {
            setActiveItem('tool-pump-complete');
        } 
        else if (location.pathname.includes('/tool/pump-new')) {
            setActiveItem('tool-pump-new');
        } 

        else if (location.pathname.includes('/tool/dexpaid')) {
            setActiveItem('tool-dexpaid');
        } 
        else if (location.pathname.includes('/tool/pump-owner')) {
            setActiveItem('tool-pump-owner');
        } 

        else if (location.pathname.includes('/tool/swap')) {
            setActiveItem('tool-swap');
        } 
    
      }, [location]); 

    return (
        //navigation
        <>
            <div className="">
                <Link to="/tool" className={activeItem === 'home' ? 'text-highlight' : ''}>
                    <img className='max-sm:hidden md:w-8 animate-spin animate-spin-logo' alt="neutron" src={Logo}/> 
                </Link>
            </div>

            <div className="">
                <Link to="/tool/pair_d" className={activeItem === 'tool-pair-d' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Pair[D]</span></Link>
            </div>
            <div className="">
                <Link to="/tool/pair_n" className={activeItem === 'tool-pair-n' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Pair[N]</span></Link>
            </div>
            {/* <div className="">
                <Link to="/tool/pair_c" className={activeItem === 'tool-pair-c' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Pair[C]</span></Link>
            </div> */}

            <div className="">
                <Link to="/tool/dexpaid" className={activeItem === 'tool-dexpaid' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">DexPaid</span></Link>
            </div>

            <div className="">
                <Link to="/tool/pump-new" className={activeItem === 'tool-pump-new' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">New</span></Link>
            </div>
            
            <div className="">
                <Link to="/tool/pump-king" className={activeItem === 'tool-pump-king' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">King</span></Link>
            </div>

            <div className="">
                <Link to="/tool/pump-complete" className={activeItem === 'tool-pump-complete' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Complete</span></Link>
            </div>

            <div className="">
                <Link to="/tool/pump-owner" className={activeItem === 'tool-pump-owner' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Owner</span></Link>
            </div>
            

            <div className="">
                <Link to="/tool/swap" className={activeItem === 'tool-swap' ? 'text-highlight' : ''}>
                    <span className="hover:font-bold hover:bg-transparent hover:text-slate-50">Swap</span></Link>
            </div>
            
        </>
    );

}
