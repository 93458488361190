import React, { useState, useEffect } from 'react';

function HomeArea(){
    const [isRotated, setRotated] = useState(false);

    const handleClick =() => {
        setRotated(!isRotated);
    }

    return (
        <div className="w-full text-white text-center">
            <div className="text-5xl mt-20">NEUTRON</div>
            <div className="text-xl color-main">Aggregator</div>
            <div className="text-center mt-10"><img  alt="neutron"
                                                className={`w-60 max-sm:100 mx-auto  ${isRotated ? 'animate-spin animate-spin-logo' : 'animate-pulse'}`}
                                                onClick={handleClick}
                                                src="/neutron-logo.png" />
            </div>
        </div>
    )
};

export default HomeArea