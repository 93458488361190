import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import SwapLog from './pages/SwapLog';
import PumpKing from './pages/PumpKing';
import PumpComplete from './pages/PumpComplete';
import PumpNew from './pages/PumpNew';
import PairD from './pages/Pair';
import PairN from './pages/PairN';
import DexPaid from './pages/DexPaid';
import Home from './pages/home/Home';
import PumpOwner from './pages/PumpOwner';


const App: React.FC = () => {
  return (
    <div className="App">
        <Router>
          <Routes>
              <Route path="/"  element={<Home/>}/> 
              <Route path="/tool/" element={<PairD/>} />
              <Route path="/tool/pair_d" element={<PairD/>} />
              <Route path="/tool/pair_n" element={<PairN/>} />
              {/* <Route path="/tool/pair_c" element={<PairD/>} /> */}

              <Route path="/tool/dexpaid" element={<DexPaid/>} />

              <Route path="/tool/pump-king" element={<PumpKing/>} />
              <Route path="/tool/pump-complete" element={<PumpComplete/>} />
              <Route path="/tool/pump-owner" element={<PumpOwner/>} />

              <Route path="/tool/pump-new" element={<PumpNew/>} />

              <Route path="/tool/swap" element={<SwapLog/>} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;


