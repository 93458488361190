export enum TokenType {
    TOKEN ='token',
    ACCOUNT = 'account',
    TX = 'tx',
}

export function solNumber(lamports : number, showZero = true){
    if(lamports == null) return "";
    if(lamports === 0 && showZero === false) return "";
    const sol = lamports / 10 ** 9;

    return beautyNumber(sol)
}


export function solCap(lamports : number){
    const sol = lamports / 10 ** 9;
    const solFormated = beautyNumber(sol);

    return sol < 10 ?(<span className="bg-red-800 px-1">{solFormated}</span>):solFormated;
}

/**
 * 
 * @param number 
 * @returns string
 */
export function beautyNumber(number : number | undefined){
    if(!number) return "";

    if (Number.isInteger(number)) {
        return number.toLocaleString('en-US');
    }

    let new_number; 
    if (number >= 0.1) 
        new_number =  number.toFixed(1);
    else if (number >= 0.01) 
        new_number = number.toFixed(2);
    else 
        new_number = number.toFixed(3);

    return new_number.toLocaleString('en-US');
}



export  function solscanLink(address, type ){ //account tx token
    return 'https://solscan.io/'+ type + '/' + address;
}

export  function shortAddress(address : string, type : TokenType) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,5);
    const lastForChars  = address.substr(-4);
    if(type === TokenType.TOKEN)
        return firstForChars + "..." + lastForChars;
    else return firstForChars + "...";
};

export function shortTokenLink(address : any, type : TokenType){
     if(address == null || address.length < 10) return "";
     const short_Token = shortAddress(address, type);
     const sol_scanLink = solscanLink(address, type);
     return (<a className="text-orange-500" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}


export function tokenLink(address, type){
    const sol_scanLink = solscanLink(address, type);
    return (<a className="text-orange-500" target="_blank" rel="noreferrer"  href={sol_scanLink}>{address}</a>);
}

export function formatNumber(amount) {
    if(amount == null) return "";
    // Chuyển đổi số sang chuỗi
    let strAmount = amount.toString();
  
    // Tách phần nguyên và phần thập phân
    let parts = strAmount.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Định dạng phần nguyên
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    // Kết hợp lại và trả về chuỗi đã định dạng
    return formattedInteger + decimalPart;
}

export function formatToken(amount) {

    return  amount.replace(/\B(?=(\d{3})+(?!\d))/g, '_');
}


export function formatSupply(supply, decimals){
    const _realSupply = realSupply(supply, decimals);
    if(_realSupply !== "") {
        return formatNumber(_realSupply.toFixed(0));
    }
        
}
export function realSupply(supply, decimals){
    if(decimals == null) return "";
    if(supply == null) return "";
    const amount = supply / 10 ** decimals;
    return amount;
}

export function varTime(time : any){ //second timestamp
    if(time == null || time === "") return '-';
    const current_unix =  Date.now() /1000; //second unit
    const distance_time = current_unix - time;//second unit
    return formatTimestamp(distance_time);
}

export function formatTimestamp(distance_time : number){
    const sign = distance_time < 0 ?'+':'';
    const distance_time_abs = distance_time >= 0?distance_time : (0-distance_time);
    const formatTime = _formatTimestamp(distance_time_abs);
    return sign + formatTime;
}


export function _formatTimestamp(distance_time : number){ //second timestamp
    var day = Math.floor(distance_time/24/3600); 
    var remain_hour = distance_time - 24 * 3600 * day; //second
    var hour = Math.floor(remain_hour/3600);
    var remain_minute = remain_hour - 3600 * hour; //second
    var minute = Math.floor(remain_minute/60);
    var remain_second = Math.floor(remain_minute - 60 * minute); //second

    if(day > 0) return day + "d";// + hour + "h";
    if(hour > 0) return hour + "h";// + minute + "'";
    if(minute > 1) return minute + "'";// + remain_second + "s";
    if(minute === 1) return minute + "'" + remain_second + "s";

    return remain_second + "s";
}

export function dexscreenerLink(mint){
    return 'https://dexscreener.com/solana/' + mint;
}

export function pumpLink(mint){
    return 'https://pump.fun/' + mint;
}

// export const BASE_API = process.env.REACT_APP_BASE_API || 'https://api.neutron.ag';
export const BASE_API = 'https://api.neutron.ag';

export function get_user_flow(mint : string, user_address : string | null){
   
    const user_in_solscan = user_address?'https://solscan.io/account/' + user_address + '?token_address=' +mint + '#transfers' : null;
    const user_in_dex = user_address?'https://dexscreener.com/solana/' + mint + '?maker=' + user_address: null;

    return <>
                {user_in_solscan?(<a target="_blank" rel="noreferrer" href={user_in_solscan}>↓</a>):''}
                {user_in_dex?(<a target="_blank" rel="noreferrer" href={user_in_dex}>↑</a>):''}
        </>                                
}

